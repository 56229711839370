<template>
  <div id="craigslist-account-form">
    <el-card>
      <el-form
        ref="craigslist-account-form"
        v-loading="loadingAccount"
        :model="form"
        :rules="rules"
        label-width="160px"
        size="medium"
      >
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>

        <el-form-item label="Password" prop="password">
          <el-input v-model="form.password" />
        </el-form-item>

        <el-form-item label="Status" prop="status">
          <el-select v-model="form.status" placeholder="Status">
            <el-option label="Active" value="active" />
            <el-option label="Inactive" value="inactive" />
          </el-select>
        </el-form-item>

        <div v-if="isStaff">
          <el-form-item label="Company">
            <el-input :value="companyName" disabled />
          </el-form-item>
          <el-form-item label="Properties" prop="properties">
            <el-select
              v-model="form.properties"
              :remote-method="fetchProperties"
              :loading="loadingProperties"
              value-key="id"
              placeholder="Type to search"
              class="multi-select"
              filterable
              multiple
              remote
            >
              <el-option
                v-for="property in propertyOptions"
                :key="property.id"
                :label="property.name"
                :value="property"
              >
                <span class="select-option-value">
                  {{ property.name }}
                </span>
                <span class="select-option-value-alt">
                  {{ property.groups.join(', ') }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="Region" prop="region">
          <el-select
            v-model="form.region"
            placeholder="Select..."
            filterable
            clearable
          >
            <el-option
              v-for="region in regionOptions"
              :key="region.value"
              :label="region.label"
              :value="region.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Proxy IP" prop="proxy_ip">
          <el-input v-model="form.proxy_ip" />
        </el-form-item>

        <el-form-item label="Proxy Port" prop="proxy_port">
          <el-input v-model.number="form.proxy_port" />
        </el-form-item>

        <el-form-item label="Proxy Username" prop="proxy_username">
          <el-input v-model="form.proxy_username" />
        </el-form-item>

        <el-form-item label="Proxy Password" prop="proxy_password">
          <el-input v-model="form.proxy_password" />
        </el-form-item>

        <el-form-item label="Notes" prop="notes">
          <el-input
            v-model="form.notes"
            type="textarea"
            :rows="2"
          />
        </el-form-item>

        <el-form-item label="Suppress Emailhandler Slack Notifications" prop="suppress_failed_email_messages">
          <el-checkbox
            v-model="form.suppress_failed_email_messages"
          />
        </el-form-item>
      </el-form>
    </el-card>

    <div class="controls">
      <el-button
        id="submit-btn"
        :loading="loading"
        type="primary"
        @click="submit"
      >
        Save
      </el-button>
    </div>

    <el-dialog
      :visible="showWarningDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :append-to-body="true"
      width="600px"
      title="Save Craigslist Account?"
    >
      <span class="warning">WARNING</span>:
      Saving these changes will result in the account changing companies. Would you like to continue?
      <span slot="footer" class="dialog-footer">
        <el-button @click="showWarningDialog = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          @click="warningSubmitHandler"
        >
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CraigslistAPI from '@/services/api/craigslist'
import RooofAPI from '@/services/api/rooof'

import regex from '@/utils/regex'

export default {
  name: 'CraigslistAccountForm',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedProperties: [],
      searchProperties: [],
      loadingAccount: false,
      loadingProperties: false,
      form: {
        email: '',
        password: '',
        status: 'active',
        region: null,
        company: null,
        properties: [],
        proxy_ip: '',
        proxy_port: null,
        proxy_username: '',
        proxy_password: '',
        notes: ''
      },
      rules: {
        email: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        password: [
          { max: 100, message: 'Password cannot be more than 100 characters', trigger: 'blur' }
        ],
        properties: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                const company = value[0].company
                for (const property of value) {
                  if (property.company !== company) {
                    return callback(new Error('All properties must belong to the same company.'))
                  }
                }
              }
              return callback()
            }
          }
        ],
        proxy_ip: [
          { pattern: regex.ip, message: 'Please provide a valid IP address', trigger: 'blur' }
        ],
        proxy_port: [
          // async-validator's numeric validation is really buggy
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback()
              }
              if (!Number.isInteger(value)) {
                return callback(new Error('Proxy port must be an integer'))
              }
              if (value > 65535) {
                return callback(new Error('Proxy port cannot be greater than 65535'))
              }
              return callback()
            }
          }
        ],
        proxy_username: [
          { max: 128, message: 'Proxy Username cannot be more than 128 characters', trigger: 'blur' }
        ],
        proxy_password: [
          { max: 128, message: 'Proxy Password cannot be more than 128 characters', trigger: 'blur' }
        ]
      },
      oldCompany: null,
      showWarningDialog: false
    }
  },
  computed: {
    companyName () {
      if (!this.form.company) {
        return null
      }
      return this.$store.getters.getCompanyById(this.form.company).human_name
    },
    identifier () {
      // The presence of `email` in the component props or
      // route path indicates we're updating this resource
      return this.email || this.$route.params.email
    },
    isStaff () {
      return this.$store.getters['auth/isStaff']
    },
    regionOptions () {
      return this.$store.state.regions.major_regions.map(region => (
        { value: region.name, label: region.name }
      )).sort((a, b) => a.label.localeCompare(b.label))
    },
    propertyOptions () {
      // We need to manage two lists of options to display in the
      // property select; one for selected account properties and
      // one for the remote search results (de-duplicated)
      const unique = {}
      for (const prop of this.form.properties) {
        unique[prop.id] = prop
      }
      for (const prop of this.searchProperties) {
        if (!unique[prop.id]) {
          unique[prop.id] = prop
        }
      }
      return Object.values(unique).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    // Refresh account data on id change
    identifier (value) {
      this.fetchAccount()
    },
    form: {
      handler (newForm) {
        if (newForm.properties.length) {
          this.form.company = newForm.properties[0].company
        }
      },
      deep: true
    }
  },
  async created () {
    if (this.identifier) {
      await this.fetchAccount()
      this.oldCompany = this.form.company
    }
  },
  methods: {
    /**
     * onClick handler for submit button.
     */
    submit () {
      this.$refs['craigslist-account-form'].validate(valid => {
        if (valid) {
          if (this.form.company === this.oldCompany || !this.oldCompany) {
            // Turn property objects back into pks
            const data = JSON.parse(JSON.stringify(this.form))
            data.properties = data.properties.map(el => el.id)
            this.$emit('submit', data)
          } else {
            // Warn user that this will change the CL account's company
            this.showWarningDialog = true
          }
        }
      })
    },
    /**
     * onClick handler for submit button in warning dialog.
     */
    warningSubmitHandler () {
      const data = JSON.parse(JSON.stringify(this.form))
      data.properties = data.properties.map(el => el.id)
      this.$emit('submit', data)
      this.showWarningDialog = false
    },
    /**
     * Fetch craigslist account data from the api.
     */
    async fetchAccount () {
      this.loadingAccount = true
      try {
        const response = await CraigslistAPI.accounts.retrieve(this.identifier)
        const propertiesCopy = response.properties
        response.properties = []
        this.form = response
        this.loadingAccount = false

        // Tranform list of property ids into property objects (non-blocking)
        if (propertiesCopy.length) {
          for (const id of propertiesCopy) {
            RooofAPI.properties.retrieve(id).then(prop => {
              this.form.properties.push(prop)
            })
          }
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
        this.loadingAccount = false
      }
    },
    /**
     * Fetch a list of properties from the api.
     *
     * @param {String} searchValue
     */
    async fetchProperties (searchValue) {
      if (searchValue !== '') {
        this.loadingProperties = true
        try {
          const params = {
            name__icontains: searchValue,
            page_size: 50
          }
          const response = await RooofAPI.properties.listPage(params)
          this.searchProperties = response.results
        } catch (err) {
          const details = err.response ? err.response.data : null
          this.$rfAlert.error(this, err, details)
        }
        this.loadingProperties = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#craigslist-account-form {
  margin-top: 1em;
  .el-form {
    width: 90%;
  }
}
.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  #submit-btn {
    margin-top: 1em;
  }
}
.multi-select {
  width: 100%;
}
.select-option-value {
  float: left;
}
.select-option-value-alt {
  float: right;
  padding-left: 20px;
  padding-right: 20px;
  color: #949ba5;
  font-size: 13px;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
  word-break: break-word;
  line-height: 1.5em;
  .warning {
    color: #f56c6c;
    font-weight: bold;
  }
}
</style>
