<template>
  <div id="craigslist-account-edit">
    <craigslist-account-form :loading="loading" @submit="updateAccount" />
  </div>
</template>

<script>
import CraigslistAccountForm from './_components/CraigslistAccountForm'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CraigslistAccountEdit',
  components: {
    'craigslist-account-form': CraigslistAccountForm
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * Update the craigslist account.
     *
     * @param {Object} data
     */
    async updateAccount (data) {
      try {
        this.loading = true
        await CraigslistAPI.accounts.update(this.$route.params.email, data)
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Account <strong>${data.email}</strong> updated`,
          type: 'success',
          duration: 3500
        })
        this.$router.push({ name: 'CraigslistAccounts' })
      } catch (err) {
        if (err.response) {
          const needsAssocation = err.response.status === 400 &&
            err.response.data.password &&
            err.response.data.password.some(
              msg => msg === 'Cannot encrypt password without any company associations'
            )
          const details = needsAssocation
            ? 'Please associate the Craigslist Account with a property before setting a password.'
            : err.response.data
          this.$rfAlert.error(this, err, details)
        } else {
          this.$rfAlert.error(this, err, null)
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
#craigslist-account-edit {
  width: 50%;
}
</style>
